import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'
import Card from '@veneer/core/dist/esm/scripts/card'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import HPXTheme, { left, right } from '../../styles/global'
import select from '@veneer/core/dist/esm/scripts/select'
import { withAnalytics } from '@veneer/analytics'
const TrackerCard = withAnalytics(Card)
const TrackerSelect = withAnalytics(select)

export const flexRow = css`
  display: flex;
  flex-direction: row;
`
const flexColumn = css`
  display: flex;
  flex-direction: column;
`
const flexAlignStart = css`
  align-items: flex-start;
  justify-content: flex-start;
`
const flexAlignCenter = css`
  align-items: center;
  justify-content: center;
`
export const Section = styled.div`
  min-height: auto;
  position: relative;
  &.size {
    flex: 1;
    height: 400px;
    width: 100%;
    max-height: calc(100vh - 40px);
  }
  ${flexColumn};
  z-index: 1;
  &.m-b-24 {
    margin-bottom: ${Tokens.space6};
  }
  .m-b-4 {
    margin-bottom: ${Tokens.space1};
  }
  .m-b-28,
  &.m-b-28 {
    margin-bottom: ${Tokens.space7};
  }
  .m-b-32 {
    margin-bottom: ${Tokens.space8};
  }
  .m-b-40 {
    margin-bottom: calc(${Tokens.space8} + ${Tokens.space2});
  }
  .flexRow {
    ${flexRow};
  }
  .icon-color {
    color: ${({ theme }) => theme.colorForeground};
  }
  .colorForegroundMedium {
    color: ${({ theme }) => theme.colorForegroundMedium};
  }
`

export const Spinner = styled(ProgressIndicator)`
  svg,
  > div {
    width: 48px;
    height: 48px;
  }
`

export const Container = styled.div`
  min-height: auto;
  ${flexColumn};
  padding: ${Tokens.space4} 0px;
  &.p-t-0 {
    padding-top: 0px;
  }
  &.p-0 {
    padding: 0px;
  }
  &.m-b-24 {
    margin-bottom: ${Tokens.space6};
  }
  &.m-b-16 {
    margin-bottom: ${Tokens.space4};
  }
  &.m-b-8 {
    margin-bottom: ${Tokens.space2};
  }
`
export const Row = styled.div`
  ${flexRow};
  ${(props) =>
    props.flexWrap &&
    css`
      flex-wrap: wrap;
    `}
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  &.full {
    height: 100%;
    width: 100%;
    flex: 1;
  }
  &.m-t-4 {
    margin-top: ${Tokens.space1};
  }
  &.m-t-8 {
    margin-top: ${Tokens.space2};
  }
  &.m-t-12 {
    margin-top: ${Tokens.space3};
  }
  &.m-t-16 {
    margin-top: ${Tokens.space4};
  }
  &.m-t-24 {
    margin-top: ${Tokens.space6};
  }
  &.m-t-32 {
    margin-top: ${Tokens.space8};
  }
  &.m-t-36 {
    margin-top: calc(${Tokens.space8} + ${Tokens.space1});
  }
  .m-l-8 {
    margin-${left}: ${Tokens.space8};
  }
  &.bg-white {
    background-color: ${({ theme }) =>
      theme.colorBackgroundContainer || Tokens.colorWhite};
  }
  ${(props) =>
    props.align &&
    css`
      justify-content: ${props.align};
      align-content: ${props.align};
      align-items: ${props.align};
      justify-items: ${props.align};
    `}
`
export const Column = styled.div`
  ${flexColumn};
  flex: 1;
  ${(props) =>
    props.flex === true &&
    css`
      height: 100%;
    `}
  ${(props) =>
    props.align &&
    css`
      justify-content: ${props.align};
      align-content: ${props.align};
      align-items: ${props.align};
      justify-items: ${props.align};
    `}
  ${HPXTheme.mediaQueries.M} {
    max-width: ${(props) => (props.M ? `${props.M}px` : '100%')};
  }
  &.m-12 {
    margin: ${Tokens.space3} 0px;
  }
  &.m-b-0 {
    margin-bottom: 0px;
  }
`

export const Header = styled.div`
  text-align: ${left};
  min-height: auto;
  ${flexRow};
  ${flexAlignStart};
  align-items: center;
  margin-bottom: ${Tokens.space4};
`

const defaultTitle = css`
  text-align: ${left};
  ${flexRow};
  font-style: normal;
  font-feature-settings: 'rclt' off;
`

export const Title = styled.span`
  ${defaultTitle}
  font-family: ${Tokens.fontTextMedium} !important;
  margin-bottom: ${Tokens.space2};
`

export const SubTitle = styled.span`
  ${defaultTitle}
  font-family: ${Tokens.fontTextRegular} !important;
  margin-bottom: ${Tokens.space3};
`

export const ContentTitle = styled.span`
  ${defaultTitle}
  margin-bottom: calc(${Tokens.space1}-2px);
  color: ${({ theme }) => theme.colorForeground};
`

export const Description = styled.span`
  ${defaultTitle}
  margin-bottom: ${Tokens.space4};
`

export const ContentDescription = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colorForegroundLight || Tokens.colorGray5};
  justify-content: flex-start;
`

export const PageTitle = styled.h6`
  ${defaultTitle}
  font-family: ${Tokens.fontTextRegular} !important;
  font-feature-settings: 'rclt' off;
  color: ${({ theme }) => theme.colorForeground};
  margin-${right}: ${Tokens.space1};
  flex: 1;
`

export const Content = styled.div`
  text-align: ${left};
  min-height: auto;
  ${flexColumn};
  ${flexAlignCenter};
  flex: 1;
  background: rgba(255, 255, 255, 0.7);
  border-radius: ${Tokens.borderRadius2};
`

export const Body = styled.div`
  text-align: ${left};
  min-height: auto;
  ${flexColumn};
  ${flexAlignStart};
  flex: 1;
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : 0};
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : 0)};
  //max-width: 1064px;
`

export const ItemContent = styled.div`
  text-align: ${left};
  ${flexColumn};
  flex: 1;
  ${(props) =>
    props.type == 'WhatsApp'
      ? css`
      margin-${left}: ${Tokens.space2};
    `
      : css`
      margin-${left}: ${Tokens.space4};`}
`

export const List = styled.ul`
  text-align: ${left};
  ${flexRow};
  padding: 0px;
  flex-wrap: wrap;
  width: 100%;
`

export const Item = styled.li`
  text-align: ${left};
  list-style: none;
  ${flexRow};
  align-items: center;
  flex: calc(50%-20px);
  margin-bottom: ${Tokens.space8};
  margin: 10px;

  &:nth-child(odd) {
    margin-${left}: 0px;
  }
  &:nth-child(even) {
    margin-${right}: 0px;
  }
`

export const Box = styled(TrackerCard)`
  ${flexRow};
  positon: relative;
  align-items: center;
  ${(props) =>
    props.background === false &&
    css`
      background: transparent !important;
      background-color: transparent !important;
      border-width: 0px !important;
      border-color: transparent !important;
    `}

  &.cardStyle {
    background: ${({ theme }) => theme.colorBackgroundContainer};
    background-color: ${({ theme }) => theme.colorBackgroundContainer};
    border-color: ${({ theme }) => theme.colorBorder};
    cursor: pointer;
    border-radius: ${Tokens.borderRadius3};
    ${(props) =>
      props.type == 'grid' &&
      props.theme.isMobile &&
      css`
        border-radius: 0px;
      `}
    ${(props) =>
      props.type == 'grid' &&
      props.first &&
      css`
        border-top-left-radius: ${Tokens.borderRadius3};
        border-top-right-radius: ${Tokens.borderRadius3};
      `}
      ${(props) =>
      props.type == 'grid' &&
      props.end &&
      css`
        border-bottom-left-radius: ${Tokens.borderRadius3};
        border-bottom-right-radius: ${Tokens.borderRadius3};
      `}
    @media screen and (-ms-high-contrast: active) {
      border-width: 1px;
    }
    &:active {
      border-width: 1px !important;
      margin: 0px !important;
    }
  }
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none !important;
    `}
  &.no-pointer {
    cursor: default !important;
  }
  &:focus {
    box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  }
  &:hover {
    &:not(.no-pointer) {
      box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
      ${({ theme }) =>
        theme.mode === 'light' &&
        css`
          border-color: ${({ theme }) => theme.colorBorder} !important;
        `}
      ${(props) =>
        props.show &&
        css`
          border: 1px solid ${Tokens.colorHpBlue6} !important;
        `}
    }
  }
  ${(props) =>
    props.show &&
    css`
      border: 1px solid ${Tokens.colorHpBlue6} !important;
    `}
  padding: ${(props) => (props.padding ? props.padding : Tokens.space4)};
  flex: 0 0 calc(100%);
  position: relative;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  transition: top 5s;
  &.m-t-16 {
    margin-top: ${Tokens.space4};
  }
  &.m-t-12 {
    margin-top: ${Tokens.space3};
  }
  &.p-t-44 {
    padding-top: calc(${Tokens.space8} + ${Tokens.space4});
  }
  &.p-40 {
    padding: calc(${Tokens.space8} + ${Tokens.space4});
  }
  &.flexStart {
    align-items: flex-start;
  }
  .phoneNumber {
    font-weight: bold;
  }
  height: 100%;
  flex: 1;
  &.speak-to-agent {
    padding: ${Tokens.space8};
    ${HPXTheme.mediaQueries.S} {
      padding: ${Tokens.space6};
    }
  }
  .break-text {
    white-space: pre-line;
    word-break: break-all;
  }
`

export const ItemTitle = styled.span`
  color: ${({ theme }) => theme.colorForeground || Tokens.colorGray10};
  margin: 0;
`

export const ItemDescription = styled.span`
  color: ${({ theme }) => theme.colorForegroundLight || Tokens.colorGray5};
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

export const Image = styled.img`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colorWhite || Tokens.colorWhite};
  ${(props) =>
    !props.theme.isMobile &&
    css`
      width: 100%;
    `};

  border-radius: ${Tokens.borderRadius3};
  ${(props) =>
    props.minWidth &&
    props.theme.isMobile &&
    css`
      min-width: 280px;
    `};
`

export const FlexRow = styled.div`
  display: ${(props) => (props.type ? props.type : 'grid')};
  position: relative;
  flex-wrap: wrap;
  width: ${(props) => (props.width ? `${props.width}` : `100%`)};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : `100%`)};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : 0};
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : 0)};
  grid-template-columns: ${(props) => {
    if (props.column) return `repeat(${props.column}, 1fr)`
    else return `repeat(auto-fill, minmax(343.5px, 1fr))`
  }};
  }
  ${(props) =>
    props.type != 'grid' &&
    props.theme.isMobile &&
    css`
      overflow-x: scroll;
      overflow-y: hidden;
      flex-wrap: nowrap;
      flex-direction: row;
      display: flex !important;
      &::-webkit-scrollbar {
        display: none;
      }
    `}
  gap: ${(props) => (props.gap === undefined ? Tokens.space4 : props.gap)};
  height: ${(props) => (props.height === undefined ? 'auto' : props.height)};
  ${(props) =>
    props.type == 'flex' &&
    css`
      gap: unset;
      margin: -${Tokens.space2};
      > div {
        padding: ${Tokens.space2};
      }
    `};
  @media only screen and (min-width: 1144px) {
    ${(props) => {
      if (props.maxWidth)
        return css`
          grid-template-columns: repeat(
            auto-fit,
            minmax(200px, ${(props.maxWidth - 32) / (props.L || 3)}px)
          ) !important;
        `
    }}
  }
  ${HPXTheme.mediaQueries.L} {
    ${(props) => {
      if (props.L)
        return css`
          grid-template-columns: repeat(${props.L}, 1fr);
        `
    }}
  }
  ${HPXTheme.mediaQueries.M} {
    ${(props) => {
      if (props.M) {
        return css`
          grid-template-columns: repeat(${props.M}, 1fr);
        `
      }
    }}
  }
  ${HPXTheme.mediaQueries.S} {
    ${(props) =>
      props.S &&
      css`
        grid-template-columns: repeat(${props.S}, 1fr);
      `}
  }

  ${HPXTheme.mediaQueries.XS} {
    ${(props) =>
      props.XS &&
      css`
        grid-template-columns: repeat(${props.XS}, 1fr);
      `}
  }

`

export const Col = styled.div`
  box-sizing: border-box;
  ${(props) =>
    props.align &&
    css`
      display: flex;
      align-content: ${props.align};
      justify-content: ${props.align};
    `}

  ${(props) =>
    props.gridColumn &&
    css`
      grid-column: ${props.gridColumn};
    `}
    ${(props) =>
      props.minWidth &&
      css`
        min-width: ${props.minWidth};
      `}

  &.col-md-12 {
    ${HPXTheme.mediaQueries.S} {
      flex-basis: 100%;
      max-width: 100%;
    }
    ${HPXTheme.mediaQueries.XS} {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  ${HPXTheme.mediaQueries.S} {
    ${(props) =>
      props.reorder &&
      css`
        order: 1;
      `}
    }
  ${HPXTheme.mediaQueries.L} {
    &.m-r-40 {
      padding-${right}: calc(${Tokens.space8} + ${Tokens.size2});
    }
    &.m-l-40 {
      padding-${left}: calc(${Tokens.space8} + ${Tokens.size2});
    }
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
      > div {
        flex: 1;
      }
    `}
`

export const IconContainer = styled.div`
  ${(props) =>
    props.type == 'WhatsApp'
      ? css`
          width: 40px;
          height: 40px;
        `
      : css`
          width: 32px;
          height: 32px;
        `}
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Label = styled.span`
  flex: 1;
  color: ${({ theme }) => theme.colorForegroundLight || Tokens.colorGray5};
  &.colorGray7 {
    color: ${Tokens.colorGray7};
  }
`

export const Value = styled.span`
  color: ${({ theme }) => theme.colorForeground};
`
export const Line = styled.hr`
  border: 0px;
  border-bottom: 1px solid
    ${({ theme }) => theme.colorForegroundLight || Tokens.colorGray5};
  width: 100%;
  margin: 0px;
`

export const ProgressBar = styled(ProgressIndicator)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
`
export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`

export const LoaderWrapper = styled(Card)`
  width: 100%;
  background: white !important;
  border-radius: 8px !important;
  box-shadow: unset !important;
  margin: 16px 0px !important;
`
export const Select = styled(TrackerSelect)`
  > div {
    border-color: transparent;
    border-radius:${(props) =>
      props.placement?.includes('bottom')
        ? `${Tokens.borderRadius4} ${Tokens.borderRadius4} 0px 0px`
        : `0px 0px ${Tokens.borderRadius4} ${Tokens.borderRadius4} `};
    @media screen and (-ms-high-contrast: active) {
      border-color: inherit;
    }
    min-width: unset;
    &:hover {
      background: transparent;
      border-color: transparent;
      @media screen and (-ms-high-contrast: active) {
        border-color: inherit;
        background: inherit;
      }
    }
  }
  > div > div {
    width: auto;
    padding-${right}: 4px;
    padding-${left}: 9px;
  }
  > div > div > div > span {
    ${(props) =>
      ['TW', 'HK', 'MO'].includes(props?.value?.[0]) === false &&
      css`
        font-size: 0px;
      `}
  }
  > div > div > div > div {
    ${(props) =>
      ['TW', 'HK', 'MO'].includes(props?.value?.[0]) === true &&
      css`
        display: none;
      `}
  }
  > div > div > div > svg {
    margin: 0px;
  }
  > div > span > div > svg {
    width: 10px;
    fill:  ${({ theme }) => theme.colorPrimary};
    @media screen and (-ms-high-contrast: active) {
      fill:  ${({ theme }) => theme.colorForegroundLight || Tokens.colorGray5};
    }
  }
`

export const CountrySelector = styled.div`
  display:flex;
  margin: -12px 0px;
  position: absolute;
  ${right}: 0px;
  .vn-option__label {
    margin-${left}: ${Tokens.space3};
  }
  .p-l-12 {
    padding-${left}: ${Tokens.space3};
  }
  >h6 {
    align-self: center;
  }
`
export const ImageLoader = styled.div`
  padding-top: ${(220 / 344) * 100}%;
  ${HPXTheme.mediaQueries.M} {
    padding-top: ${(220 / 282) * 100}%;
  }
  ${HPXTheme.mediaQueries.S} {
    padding-top: ${(220 / 176) * 100}%;
  }
  ${HPXTheme.mediaQueries.XS} {
    padding-top: ${(220 / 202) * 100}%;
  }
  background: #f2f2f2;
  width: 100%;
  border-radius: 12px;
`
export const DefaultFlag = styled.div`
  min-height: auto;
  width: 24px;
  margin-${right}: 8px;
`
export const DropDownTitle = styled.h6`
  color: ${({ theme }) => theme.colorForegroundMedium};
`
export const Banner = styled.div`
  background: ${({ theme }) => theme.colorNeutralBackground};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 8px;
  min-height: 28px;
  text-align: center;
  padding: 0px 4px 0px 8px;
  >svg {
    margin-${right}: 8px;
  };
  ${({ theme }) =>
    theme?.isMobile &&
    css`
      flex-direction: column;
    `}
`
