import { WhenJWebReady } from '@jarvis/jweb-core'
import {
  SupportAPIClient,
  getSupportConsent
} from '@jarvis/web-support-commons'
import { get } from '@jarvis/web-support-commons/dist/asyncStorage'
import {
  IContactOption,
  IGetWorkingHoursRequest,
  IGetWorkingHoursResponse
} from '@jarvis/web-support-commons/dist/ccls/support/types'
import {
  eServiceAvailable,
  getAppInfo
} from '@jarvis/web-support-commons/dist/deviceInfo/hostInfo'
import { formatSupportCode } from '@jarvis/web-support-commons/dist/deviceInfo/deviceCommon'
import { getFileContent } from '@jarvis/web-support-commons/dist/fileUtils/fileUtil'
import Config from '../../api/config'
import { IDevice, ISupportPayload, countrycodeName } from '../types'
import { getResourcesByName } from '@jarvis/react-hpx-support-shared'
import React from 'react'
const USE_CASE = 'HPX05'
// const MOBILE_USE_CASE = 'SANC21'
const MsgCodes_RetailerWarranty = [
  '452',
  '453',
  '454',
  '455',
  '457',
  '458',
  '459',
  '503'
]

export const ensureJWeb = async () => {
  const res = await WhenJWebReady
  if (res) {
    return res.JWeb.platform
  }
}

export const fetchRequest = async (
  payload: ISupportPayload
): Promise<IGetWorkingHoursResponse> => {
  const platform = await ensureJWeb()
  const { device, country, stack, language } = payload
  const basePath =
    platform == ('ios' || 'android' || 'mac')
      ? Config[stack]?.CCLS
      : (await get('@env/CCLS_APIURL')) || Config[stack]?.CCLS
  const SupportClient = new SupportAPIClient({
    basePath,
    apiKey: ''
  })
  const cacheData = await getSupportCache()
  if (!cacheData) {
    const request: IGetWorkingHoursRequest = await getWorkingHoursRequest(
      device,
      country,
      language
    )
    return await SupportClient.getSupportResources(request)
  } else {
    return cacheData
  }
}

export const getSupportResources = async (
  ContactOptionsFull: IContactOption[],
  device
) => {
  const isEServiceAvailable = await eServiceAvailable()
  const resources: IContactOption[] = ContactOptionsFull?.filter((item) => {
    if (item.serviceType == '9') {
      return isEServiceAvailable
    }
    return (
      ((item.serviceType &&
        item.serviceType != '60' &&
        item.serviceType != '55' &&
        item.serviceType != '3' &&
        item?.preferred != 2) ||
        ((item.section === 1 || item.serviceId === 'ServiceCenter') &&
          item?.preferred != 2)) &&
      checkMsgCodeRetailerWarranty(device, item)
    )
  })
  const chatResource = await getResourcesByName('OCChat', (key) => key)
  const contacts: IContactOption[] = ContactOptionsFull?.filter(
    (item) =>
      item.section === 0 &&
      ((item.serviceType == '60' &&
        chatResource != null &&
        chatResource?.length > 0) ||
        item.serviceType == '55' ||
        item.serviceType == '3') &&
      item?.preferred != 2
  )?.sort(fnSort)
  const socialMedia: IContactOption[] = ContactOptionsFull?.filter(
    (item) => item?.preferred == 2
  )
  return { resources, contacts, socialMedia }
}

function fnSort(a, b) {
  const isortA = parseInt(a.sort || '0')
  const isortB = parseInt(b.sort || '0')
  if (isortB < isortA) {
    return 1
  } else if (isortB > isortA) {
    return -1
  } else {
    return 0
  }
}

export const checkMsgCodeRetailerWarranty = (device, resource) => {
  if (resource.serviceType != '19') return true
  const msgCodes = device?.warrantyInfo?.msgCodes?.split(',') || []
  return MsgCodes_RetailerWarranty.find((val) => msgCodes.includes(val))
    ? false
    : true
}

const getSupportCache = async () => {
  try {
    const cacheFileName = 'ccls_options.json'
    const fileContent = await getFileContent(cacheFileName)
    if (fileContent) {
      return JSON.parse(fileContent)
    }
  } catch (e) {
    console.log('ccls_options ' + e)
  }
}

const getWorkingHoursRequest = async (
  device?: IDevice,
  country?: string,
  language?: string
): Promise<IGetWorkingHoursRequest> => {
  let requestPara
  const platform = await ensureJWeb()
  const optin = await getSupportConsent()
  const procuctNumber = [70, 80, 100].includes(device?.ProductType)
    ? device?.AltProductNumber?.split('#')?.[0]
    : device?.ProductNumber?.split('#')[0]
  if (platform === 'web') {
    requestPara = {
      AppVersion: null,
      BoD: null,
      Country: country || 'GB',
      DateTime: new Date().toISOString(),
      Install: 0,
      Language: language,
      Modifier: null,
      Offset: '+0800',
      PrevSeq: 0,
      ProductNumber: procuctNumber,
      ProductBigSeriesOid: device?.ProductBigSeriesOID,
      ProductLine: device?.ProductLineCode,
      ProductNameOid: device?.ProductNameOID,
      ProductSeriesOid: device?.ProductSeriesOID,
      ResponseFmt: 1,
      ServiceType: 'All',
      Slc: null,
      UseCase: USE_CASE,
      UseRequestTime: 0,
      WarrantyLevel: getWarrantyLevel(device, optin),
      MiscParm: formatSupportCode(device?.SupportCode)
    }
  } else {
    if (device) {
      const appInfo = await getAppInfo()
      requestPara = {
        AppVersion: appInfo?.appVersion,
        BoD: null,
        Country: country,
        DateTime: new Date().toISOString(),
        Install: 0,
        Language: language,
        Modifier: null,
        Offset: '+0800',
        PrevSeq: 0,
        ProductBigSeriesOid: device?.ProductBigSeriesOID,
        ProductLine: device?.ProductLineCode,
        ProductNameOid: device?.ProductNameOID,
        ProductNumber: procuctNumber,
        ProductSeriesOid: device?.ProductSeriesOID,
        ResponseFmt: 1,
        ServiceType: 'All',
        Slc: null,
        UseCase: USE_CASE,
        UseRequestTime: 0,
        WarrantyLevel: getWarrantyLevel(device, optin),
        MiscParm: formatSupportCode(device?.SupportCode)
      }
    }
  }
  return requestPara
}

const getWarrantyLevel = (device, optin) => {
  let statusState = device?.IsSubscriptionDevice
    ? device?.WarrantyState
    : optin
    ? device?.warrantyInfo?.state
    : 'ZZ'

  if (optin && !device?.IsSubscriptionDevice) {
    if (!device?.warrantyInfo?.state) {
      statusState = getWarrantyState(device?.warrantyInfo?.statusCode)
    }
    if (device?.warrantyInfo?.statusCode == 1) {
      if (device?.warrantyInfo?.carepack === true) statusState = 'XW'
      if (device?.warrantyInfo?.carepack === false) statusState = 'IW'
    }
  }
  return statusState || device?.WarrantyState || 'UN'
}

const getWarrantyState = (statusCode) => {
  const STATUS_CODE = {
    1: 'XW',
    2: 'IW',
    3: 'WE',
    4: 'UN',
    5: 'OW',
    6: 'MI',
    21: 'IW',
    24: 'LR',
    25: 'IA',
    22: 'OH',
    7: '452',
    8: '453',
    9: '455',
    10: '503/458',
    11: '457',
    12: 'CP',
    0: 'ZZ'
  }
  return STATUS_CODE[statusCode] || 'UN'
}

export const getCountries = async (
  localeString: string
): Promise<countrycodeName[]> => {
  let filteredCountry = []

  const countrynameJSON = await import(`../../utils/country_list`).then(
    (result) => {
      return result
    }
  )
  const countryList = Object.keys(countrynameJSON.default).map(function (
    itemNamedIndex
  ) {
    const countrieObj = {
      language: itemNamedIndex,
      countries: countrynameJSON.default[itemNamedIndex]
    }
    return countrieObj
  })

  if (localeString) {
    filteredCountry = countryList.filter((item) => {
      return item.language === localeString
    })
  }
  if (filteredCountry?.length == 0) {
    filteredCountry = countryList.filter((item) => {
      return item.language === 'en-US' //default
    })
  }

  let countryArr: countrycodeName[] = []
  if (filteredCountry.length > 0) {
    const keys = Object.keys(filteredCountry[0].countries)
    keys.forEach((key) => {
      if (!key.includes('Rg_'))
        countryArr.push({ code: key, name: filteredCountry[0].countries[key] })
    })
    countryArr = countryArr.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  }
  return countryArr
}
/**
 * Easily handle strings and boolean
 * @param val
 * @returns boolean
 */
export function parseBool(val) {
  return val === true || val === 'true'
}
export function genTestId(
  moduleId: string,
  componentId: string,
  suffix?: string
): string {
  const allowedCharsTrkId = /^([A-Z][a-z]+)+?[0-9.]*?$/
  if (moduleId.length > 4) {
    moduleId = moduleId.slice(0, 4)
  }

  if (componentId.length > 28) {
    componentId = componentId.slice(0, 28)
  }
  // JWebTrackId should not be longer than 32 characters because of simpleUi event schema constraints for the 'componentId' field
  const testJWebId = `${moduleId}${componentId}`

  if (!allowedCharsTrkId.test(testJWebId))
    return testJWebId.replace(/[^A-Za-z0-9.]/gi, '')

  return testJWebId
}

export function fetchCountryForDropDown(
  lang: string,
  FlagList: any
): { value: string; label: string; icon: JSX.Element; disabled: boolean } {
  const Flag = FlagList[`Flag${lang.toUpperCase()}`]
  return {
    value: lang,
    label: ``,
    icon: React.createElement(Flag, {
      key: 0,
      style: {
        minWidth: '24px'
      }
    }),
    disabled: true
  }
}
