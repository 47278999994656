import { Stack } from '../types/stratus'

export interface IConfig {
  [key: string]: IValue
}

interface IValue {
  CALL_ME: ({ country, language, cacheToken, isLocaleMatch }) => string
  CCLS: string
}

const Config: IConfig = {
  [Stack.dev]: {
    CALL_ME: ({ country, language, cacheToken, isLocaleMatch = true }) =>
      isLocaleMatch
        ? `https://uat.support.hp.com/${country.toLowerCase()}-${language.toLowerCase()}/contact?originid=myhp&cachetoken=${cacheToken}`
        : `https://uat.support.hp.com/${country.toLowerCase()}-${language.toLowerCase()}/contact?openCLC=true`,
    CCLS: 'https://ccls-itg.external.hp.com/helpandsupport/ContactCenters/ContactCenters.svc/'
  },
  [Stack.pie]: {
    CALL_ME: ({ country, language, cacheToken, isLocaleMatch = true }) =>
      isLocaleMatch
        ? `https://uat.support.hp.com/${country.toLowerCase()}-${language.toLowerCase()}/contact?originid=myhp&cachetoken=${cacheToken}`
        : `https://uat.support.hp.com/${country.toLowerCase()}-${language.toLowerCase()}/contact?openCLC=true`,
    CCLS: 'https://ccls-itg.external.hp.com/helpandsupport/ContactCenters/ContactCenters.svc/'
  },
  [Stack.stage]: {
    CALL_ME: ({ country, language, cacheToken, isLocaleMatch = true }) =>
      isLocaleMatch
        ? `https://uat.support.hp.com/${country.toLowerCase()}-${language.toLowerCase()}/contact?originid=myhp&cachetoken=${cacheToken}`
        : `https://uat.support.hp.com/${country.toLowerCase()}-${language.toLowerCase()}/contact?openCLC=true`,
    CCLS: 'https://ccls-itg.external.hp.com/helpandsupport/ContactCenters/ContactCenters.svc/'
  },
  [Stack.prod]: {
    CALL_ME: ({ country, language, cacheToken, isLocaleMatch = true }) =>
      isLocaleMatch
        ? `https://support.hp.com/${country.toLowerCase()}-${language.toLowerCase()}/contact?originid=myhp&cachetoken=${cacheToken}`
        : `https://support.hp.com/${country.toLowerCase()}-${language.toLowerCase()}/contact?openCLC=true`,
    CCLS: 'https://ccls.external.hp.com/helpandsupport/ContactCenters/ContactCenters.svc/'
  }
}

export const getStack = (stack: Stack) => stack || Stack.prod

export default Config
