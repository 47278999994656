/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useCallback
} from 'react'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import {
  Body,
  Column,
  Container,
  Row,
  FlexRow,
  Col,
  Section,
  Banner
} from '../styles'
import ListView from '../ListView'
import CountryDropdown from './CountryDropdown'
import SpeakToAgent from '../../SpeakToAgent'
import LiveAssistance from '../LiveAssistance'
import AnimatedHeightContainer from '@veneer/core/dist/scripts/shared/animated_height'
import HeaderContainer from './Header'
import BundledDevice from '../../BundledDevice'
import useContextAPI from '../../../hooks/useContextAPI'
import useSupport from '../../../hooks/useSupport'
import useLauncher from '../../../hooks/useLauncher'
import useLocalStorage from '../../../hooks/useLocalStorage'
import ResourceIcon from '../ResourceIcon'
import {
  ComponentSupportResourcesName,
  ComponentSocialMediaName
} from '../../../redux/support/constants'

const Resources = (props) => {
  const firstRender = useRef(true)
  const { t } = useI18n()
  const { Skelton, stack, device } = props
  const {
    authProvider,
    localization,
    featureFlag,
    environment: { isMobile },
    formatAutoId
  } = useContextAPI()
  const [country, setCountry] = useLocalStorage('supportCountry', [])
  console.log(`Country is ${country}`)
  const { isSignedIn } = authProvider
  const {
    resources,
    contacts,
    socialMedia,
    isLoading,
    countries,
    supportDevice
  } = useSupport({
    device,
    country,
    stack,
    locale: localization?.locale
  })
  const { loader, show, setShow, resource, contactLauncher } = useLauncher({
    stack,
    device: supportDevice
  })

  const accessories =
    device?.Subscriptions?.[0]?.Entities?.[0]?.LinkedEntities?.filter(
      (device) => device?.Type === 'accessories'
    )
  const IsSubscriptionPrinter = useCallback(() => {
    return (
      device?.ProductType === 1 &&
      device?.IsSubscriptionDevice &&
      device?.IsSubscriptionActive
    )
  }, [
    device?.IsSubscriptionDevice,
    device?.IsSubscriptionActive,
    device?.ProductType
  ])

  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    }
  })

  useEffect(() => {
    setShow(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const CountryList = useMemo(
    () => (
      <>
        {device?.DeviceInfo?.locale?.country && (
          <CountryDropdown
            setCountry={setCountry}
            country={
              country?.length > 0
                ? country
                : [`RU`, `BY`].includes(device?.DeviceInfo?.locale?.country)
                ? [`KZ`]
                : [device?.DeviceInfo?.locale?.country]
            }
            countries={countries}
            Skelton={Skelton}
          ></CountryDropdown>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countries?.length, country, device?.DeviceInfo?.locale?.country]
  )
  return (
    <Section>
      {CountryList}
      {isLoading && Skelton && (
        <Skelton title={null} resources={false}></Skelton>
      )}
      {!isLoading && contacts?.length > 0 && (
        <Column>
          <Container className={isSignedIn ? 'p-0 m-b-8' : 'p-0 m-b-16'}>
            <HeaderContainer
              title={t('common.header', 'Live assistance')}
              id={formatAutoId('live-assistance')}
            ></HeaderContainer>
            {device?.IsSubscriptionDevice && device?.IsSubscriptionActive && (
              <Banner
                id={formatAutoId('available247-ExpertAssistance')}
                role="banner"
              >
                <ResourceIcon
                  type={'HowToDixon'}
                  size={24}
                  color="colorGrey"
                ></ResourceIcon>
                <span className="label-small">
                  {t(
                    'common.available247ExpertAssistance1',
                    'Available 24/7 expert assistance from your All-In Plan'
                  )}
                </span>
              </Banner>
            )}
            <FlexRow maxWidth={1064} column={3} L={3} M={3} S={3} XS={2}>
              {/* {warranty?.status == 'Expired' &&
                device.IsSubscriptionDevice == false && (
                  <Col>
                    <span className="caption-small flexRow">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t('common.yourWarrantyExpired', {
                            date: getDateLocale(warranty?.warrantyEndDate),
                            interpolation: { escapeValue: false }
                          })
                        }}
                      ></p>
                      &nbsp;
                      <a href="#">
                        {t(
                          'common.extendCoverage',
                          'Extend your HP support coverage today.'
                        )}
                      </a>
                    </span>
                  </Col>
                )} */}
              {contacts &&
                contacts.map((contact, index) => (
                  <LiveAssistance
                    key={index}
                    contact={contact}
                    disabled={
                      contact.serviceType == '3' &&
                      device?.SupportCode?.includes('20') &&
                      (device?.DeviceId == 0 || device?.DeviceId == undefined)
                    }
                    index={index}
                    device={supportDevice}
                    show={show}
                    loader={loader}
                    contactLauncher={contactLauncher}
                    resource={resource}
                  ></LiveAssistance>
                ))}
              {isSignedIn && (
                <Col gridColumn="1/-1" minWidth="calc(100vw - 80px)">
                  <AnimatedHeightContainer
                    duration={500}
                    auto={false}
                    in={show && !isMobile}
                    aria-controls="speak-to-agent"
                  >
                    <SpeakToAgent
                      setShow={setShow}
                      show={show && !isMobile}
                      device={supportDevice}
                      resource={resource}
                    ></SpeakToAgent>
                  </AnimatedHeightContainer>
                </Col>
              )}
            </FlexRow>
          </Container>
        </Column>
      )}
      {featureFlag?.deviceListEnabled && accessories?.length > 0 && (
        <Container>
          <Column>
            <HeaderContainer
              title={t('Bundled devices', 'Bundled devices')}
              id={formatAutoId('bundled-devices')}
            ></HeaderContainer>
            <Body>
              <BundledDevice devices={accessories}></BundledDevice>
            </Body>
          </Column>
        </Container>
      )}
      {isLoading && Skelton && (
        <Skelton title={null} liveassistance={false}></Skelton>
      )}
      {!isLoading && socialMedia?.length > 0 && (
        <Container className={contacts?.length == 0 ? 'p-t-0' : ''}>
          <Column>
            <HeaderContainer
              title={t(
                'common.socialmessagingChannels',
                'Social messaging channels'
              )}
              id={formatAutoId('social-messaging-channels')}
            ></HeaderContainer>
            <Body>
              <ListView
                type="flex"
                flex="auto"
                device={supportDevice}
                contactLauncher={contactLauncher}
                resources={socialMedia}
                description={false}
                analyticsTitle={ComponentSocialMediaName}
              ></ListView>
            </Body>
          </Column>
        </Container>
      )}
      {!isLoading && (resources?.length > 0 || IsSubscriptionPrinter()) && (
        <Container
          isBackground={true}
          className={
            contacts?.length == 0 && socialMedia?.length == 0 ? 'p-t-0' : ''
          }
        >
          <Row>
            <Column>
              <HeaderContainer
                title={t('common.resources', 'Resources')}
                id={formatAutoId('resources')}
              ></HeaderContainer>
              <Body>
                <ListView
                  type="grid"
                  flex="100%"
                  className="resources"
                  contactLauncher={contactLauncher}
                  device={supportDevice}
                  resources={resources}
                  analyticsTitle={ComponentSupportResourcesName}
                ></ListView>
              </Body>
            </Column>
          </Row>
          {/* <Row>
          <Column>
            <Header>
              <ContentTitle>Assisted Support</ContentTitle>
            </Header>
            <Body marginBottom="0">
              <ListView
                launcher={launcher}
                resources={contacts?.filter(
                  (e) => e.serviceId !== 'ServiceCenter'
                )}
              ></ListView>
            </Body>
          </Column>
        </Row> */}
        </Container>
      )}
    </Section>
  )
}

export const MemoizedResources = Resources
