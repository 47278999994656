import React from 'react'
import { FlexRow, Col, Box } from '../SupportResources/styles'
import useContextAPI from '../../hooks/useContextAPI'
import IconKeyboard from '@veneer/core/dist/scripts/icons/icon_keyboard'
import IconMouse from '@veneer/core/dist/scripts/icons/icon_mouse'

const DeviceIcon = {
  // eslint-disable-next-line react/display-name
  7: ({ size }) => (
    <IconKeyboard size={size} color="colorForegroundLight"></IconKeyboard>
  ),
  // eslint-disable-next-line react/display-name
  8: ({ size }) => (
    <IconMouse size={size} color="colorForegroundLight"></IconMouse>
  )
}

const BundledDevice = ({ devices }) => {
  const { navigation, navigatePath } = useContextAPI()

  const redirect = (device) => {
    navigation.push(`/${navigatePath}/${device?.SerialNumber}`)
    // navigation.push(`/support/device/${device?.SerialNumber}`)
  }

  return (
    <FlexRow maxWidth={704} column={3} L={3} M={4} S={3}>
      {devices?.map((device, index) => (
        <Col key={index}>
          <Box
            tabIndex={0}
            onClick={() => {
              redirect(device)
            }}
            className="cardStyle"
            content={
              <FlexRow column={1}>
                <Col align="center" className="m-b-28">
                  {DeviceIcon[7]({ size: 120 })}
                </Col>
                <Col>
                  <span className="label">{device?.Name}</span>
                </Col>
              </FlexRow>
            }
          ></Box>
        </Col>
      ))}
    </FlexRow>
  )
}
export default BundledDevice
