import { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { StoreStateType } from '../types/store'
import { CoptorAuth } from '@jarvis/web-support-commons/dist/methone/authorization'
import { IProfile } from '@jarvis/web-support-commons/dist/methone/profile/types'
import { LocalizationType } from '../types/localization'
import { NavigationType } from '../types/shell'

interface IAuthProvider {
  setAuthToken: () => CoptorAuth | string
  checkIsLoggedIn: () => Promise<boolean>
  checkAuth: () => CoptorAuth | string
  setIsSignedIn: (isSignedIn: boolean) => void
  isSignedIn: boolean
  auth: CoptorAuth | string
  profile: IProfile
  getProfile: () => IProfile
}

interface IModal {
  modals: any
  openModal: (modal) => void
  setResize: (name: string, resize: boolean) => void
  closeModal: (key: string) => void
  setLoading: (key: string, val: boolean) => void
  isOpen: (key: string) => boolean
}
interface IOptinProvider {
  optin: boolean
  setOptin: (val: boolean) => void
  optinConfirm: (param: any) => boolean
}

interface IFeatureFlag {
  deviceListEnabled: boolean
  isSupportHomeEnabled: boolean
}
interface IEnvironment {
  platform: string
  isNative: boolean
  isMobile: boolean
}

interface IData {
  showBanner: boolean
}

interface ISharedProvider {
  data: IData
  setData: (data) => void
}

type Mode = 'light' | 'dark' | 'contrast'
type Direction = 'rtl' | 'ltr' | 'auto'

interface IThemeProvider {
  dir: Direction
  mode: Mode
}

interface IContext {
  useModal?: () => IModal
  formatAutoId?: (val: string) => string
  openDialog?: (args: unknown) => void
  closeDialog?: () => void
  loading?: boolean
  setLoading?: (val: boolean) => void
  store?: StoreStateType
  sharedProvider?: ISharedProvider
  authProvider?: IAuthProvider
  refreshProvider?: IRefreshProvider
  themeProvider?: IThemeProvider
  updateDeviceListBySN?: (param: any) => void
  getWarrantyInfo?: (
    SerialNumber,
    ProductNumber,
    Locale,
    CountryCode,
    userOptin
  ) => void
  getSubscriptionInfo?: (
    SerialNumber,
    ProductNumber,
    Locale,
    CountryCode
  ) => void
  localization?: LocalizationType
  dir?: string
  optinProvider?: IOptinProvider
  navigation?: NavigationType
  featureFlag?: IFeatureFlag
  environment?: IEnvironment
  navigatePath?: string
}

interface IRefreshProvider {
  refreshList: IRefreshList
  refreshComponent: (val: IRefreshList) => void
}

interface IRefreshList {
  [key: string]: unknown
}

const useContextAPI = () => {
  const {
    useModal,
    formatAutoId,
    openDialog,
    closeDialog,
    loading,
    setLoading,
    store,
    sharedProvider,
    authProvider,
    refreshProvider,
    updateDeviceListBySN,
    getWarrantyInfo,
    localization,
    optinProvider,
    navigation,
    featureFlag,
    dir,
    environment,
    themeProvider,
    navigatePath
  } = useContext<IContext>(RootContext)

  return {
    useModal,
    formatAutoId,
    openDialog,
    closeDialog,
    loading,
    setLoading,
    store,
    sharedProvider,
    authProvider,
    refreshProvider,
    updateDeviceListBySN,
    getWarrantyInfo,
    localization,
    optinProvider,
    navigation,
    featureFlag,
    dir,
    environment,
    themeProvider,
    navigatePath
  }
}

export default useContextAPI
