import { useContext } from 'react'
import {
  IDeviceListResponse,
  MethoneAPIClient,
  SubscriptionInfo
} from '@jarvis/web-support-commons'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { CoptorAuth } from '@jarvis/web-support-commons/dist/methone/authorization'
import { LocalizationType } from '../types/localization'

interface IMethoneWinClient {
  getDeviceId: (serialNumber) => number
  getDeviceList: () => IDeviceListResponse
  setAuth: (auth: CoptorAuth | string) => void
  confirmWarranty: ({
    SerialNumber,
    ProductNumber,
    Locale,
    CountryCode
  }) => Promise<SubscriptionInfo>
  refershDevices: (deviceListEnabled, jobStatusCheckInterval) => Promise<any>
}
interface IContext {
  MethoneClient?: MethoneAPIClient
  MethoneWinClient?: IMethoneWinClient
  localization?: LocalizationType
}

const useMethoneClient = () => {
  const { MethoneClient, MethoneWinClient } = useContext<IContext>(RootContext)

  return { MethoneClient, MethoneWinClient }
}

export default useMethoneClient
