/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Header, PageTitle } from '../../styles'

const HeaderContainer = ({ title, id }) => {
  return (
    <Header>
      <PageTitle className="subtitle-regular" id={id} tabIndex={-1}>
        {title}
      </PageTitle>
    </Header>
  )
}

export default HeaderContainer
