export const Support = 'Support'
export const SupportDevice = 'SupportDevice'
export const BaseScreenPath = '/HpxWinClient'
export const DefaultScreenName = 'DeviceSupport'
export const DeviceScreenName = 'SupportResources'

export const TestIDPrefix = 'Supp'
export const ContainerComponent = 'ResourcesComponent'
export const MFEname = 'DeviceSupportResources'
export const DeviceComponent = 'DeviceComponent'
export const ProductIconComponent = 'ProductIconBtnComponent'
export const SupportResourceItemComponent = 'Resource'
export const SupportLiveAsiistanceItemComponent = 'LiveAsistance'
export const ComponentSupportResourcesName = 'SupportResources'
export const ComponentLiveAssistanceName = 'LiveAsistance'
export const ComponentSocialMediaName = 'SocialMedia'
