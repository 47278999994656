import { useEffect, useState } from 'react'
import {
  call,
  chat,
  speak,
  call_md,
  chat_md,
  speak_md,
  call_xmd,
  chat_xmd,
  speak_xmd,
  call_sm,
  chat_sm,
  speak_sm,
  call_xs,
  chat_xs,
  speak_xs
} from '../assets/images'
import useMediaQuery from '@veneer/core/dist/scripts/utils/use_media_query'
import useContextAPI from './useContextAPI'

const useImage = () => {
  const [image, setImage] = useState({
    call,
    chat,
    speak
  })
  const medium = useMediaQuery(`(max-width: 1008px)`)
  const extraMedium = useMediaQuery(`(max-width: 952px)`)
  const small = useMediaQuery(`(max-width: 640px)`)
  const extraSmall = useMediaQuery(`(max-width: 500px)`)
  const [loading, setLoading] = useState(true)
  const {
    environment: { isMobile }
  } = useContextAPI()

  useEffect(() => {
    if (isMobile) {
      setImage({
        call: call_xmd,
        chat: chat_xmd,
        speak: speak_xmd
      })
    } else if (extraSmall) {
      setImage({
        call: call_xs,
        chat: chat_xs,
        speak: speak_xs
      })
    } else if (small) {
      setImage({
        call: call_sm,
        chat: chat_sm,
        speak: speak_sm
      })
    } else if (extraMedium) {
      setImage({
        call: call_xmd,
        chat: chat_xmd,
        speak: speak_xmd
      })
    } else if (medium) {
      setImage({
        call: call_md,
        chat: chat_md,
        speak: speak_md
      })
    } else {
      setImage({
        call,
        chat,
        speak
      })
    }
  }, [medium, small, extraMedium, extraSmall, isMobile])

  const onLoad = () => {
    setLoading(false)
  }

  const onError = () => {
    setLoading(true) // set src to default image
  }

  return {
    ...image,
    onLoad,
    onError,
    loading
  }
}

export default useImage
