import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSupportResponse,
  supportAction,
  getIsLoading
} from '../redux/support'
import { setIsLoading } from '../redux/support/redux'
import useContextAPI from './useContextAPI'
import usePrevious from './usePrevious'
import React from 'react'
import { DefaultFlag } from '../components/SupportResources/styles'
import { fetchCountryForDropDown, getCountries } from '../redux/support/utils'
const extCountries = ['TW', 'HK', 'MO']

const useSupport = ({ device, country, stack, locale }) => {
  const [countries, setCountries] = useState<any[]>([])
  const supportDevice = getSupportDevice(device, country)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)
  const { localization, sharedProvider } = useContextAPI()
  const { resources, contacts, socialMedia } = useSelector(getSupportResponse)
  const firstRender = useRef(true)
  const prevCountry = usePrevious(firstRender.current ? undefined : country)
  const { setData } = sharedProvider

  useEffect(() => {
    setData({ showBanner: contacts?.length > 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts])

  useEffect(() => {
    let flag = false
    dispatch(setIsLoading(firstRender.current))
    if (device?.IsSubscriptionDevice) {
      flag = device?.subscriptionInfo ? true : false
    } else {
      flag = device?.warrantyInfo ? true : false
    }
    flag = device?.IsHostPC ? device?.OIDInfoReady : flag
    if (device && country && flag && device?.DeviceInfo?.locale?.country) {
      if (firstRender.current) firstRender.current = false
      setData({ showBanner: false })
      dispatch(
        supportAction({
          device: {
            ...device
          },
          stack,
          country:
            country[0] ||
            ([`RU`, `BY`].includes(device?.DeviceInfo?.locale?.country)
              ? `KZ`
              : device?.DeviceInfo?.locale?.country),
          language: localization?.language,
          showLoader: country != prevCountry
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    device?.SerialNumber,
    device?.SupportCode,
    device?.WarrantyState,
    device?.warrantyInfo?.status,
    device?.DeviceInfo?.locale?.country,
    device?.OIDInfoReady,
    country
  ])

  useEffect(() => {
    if (device) fetchCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, device?.DeviceInfo?.locale?.country])

  const fetchCountries = async () => {
    const countryList = await getCountries(locale)
    const items = [] as any
    const FlagList = await import('@veneer/flags/dist/index')
    countryList?.map((country, index) => {
      const Flag = FlagList[`Flag${country.code.toUpperCase()}`]

      if (Flag && !extCountries.includes(country.code)) {
        items.push({
          value: country.code,
          label: country.name,
          icon: React.createElement(Flag, {
            key: index,
            style: {
              minWidth: '24px'
            }
          })
        })
      } else {
        items.push({
          value: country.code,
          label: country.name,
          icon: React.createElement(DefaultFlag)
        })
      }
    })
    if (
      device?.DeviceInfo?.locale?.country &&
      !items.find((item) => item.value === device?.DeviceInfo?.locale?.country)
    ) {
      items.push(
        fetchCountryForDropDown(device?.DeviceInfo?.locale?.country, FlagList)
      )
    }
    setCountries(items)
  }

  function getSupportDevice(device, country) {
    return {
      ...device,
      DeviceInfo: {
        locale: {
          country: country?.[0] || device?.DeviceInfo?.locale?.country
        }
      }
    }
  }

  return {
    isLoading,
    resources,
    socialMedia,
    contacts,
    countries,
    supportDevice
  }
}

export default useSupport
