import { useCallback, useEffect, useRef, useState } from 'react'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import {
  createProcess,
  deviceInfo,
  isFileExist,
  launchUrlFromCommand,
  launchHPSmart,
  managedConsent,
  setSupportConsent,
  showPrivacySettingsDialog
} from '@jarvis/web-support-commons'
import SupportHour from '../components/SupportHours'
import CreateCase from '../components/CreateCase'
import useContextAPI from './useContextAPI'
import { call, chat, speak } from '../assets/images'
import useCases from './useCases'
import useAddDevice from './useAddDevice'
import {
  ICacheUserRequest,
  ICacheUserResponse
} from '@jarvis/web-support-commons/dist/methone/cache/types'
import Config from '../api/config'
import useMethoneClient from '../api/methone'
import { left } from '../styles/global'
import { router } from '../redux/device/utils'
const contactMap = {
  Chat: chat,
  OCChat: chat,
  Phone: speak,
  Call: call,
  default: call
}

const modalConfig = {
  isOpen: true,
  hide: false,
  resize: false,
  sessionStatus: false,
  isResizable: false
}

const OPTIN_RESOURCES = [3, 60, 55]

const OMNI_CHAT_CMD = '"HPSALauncher.exe" /OmniCCChat %serialNumber% /UseCCC'

const useLauncher = ({ stack, device }) => {
  const {
    useModal,
    openDialog,
    closeDialog,
    authProvider,
    refreshProvider,
    getWarrantyInfo,
    localization,
    formatAutoId,
    optinProvider,
    dir,
    navigation,
    themeProvider,
    environment,
    navigatePath
  } = useContextAPI()
  const { MethoneClient, MethoneWinClient } = useMethoneClient()
  const { setAuthToken, isSignedIn } = authProvider
  const { optin, optinConfirm } = optinProvider || {}
  const { addDevice } = useAddDevice()
  const { t } = useI18n()
  const { openModal, closeModal, setLoading } = useModal()
  const { setIsSubmitted, isSubmitted } = useCases()
  const [resource, setResource] = useState()
  const [show, setShow] = useState(false)
  const [loader, setLoader] = useState(false)
  const resourceRef = useRef(null)

  useEffect(() => {
    if (!isSignedIn) {
      setLoader(false)
      setShow(false)
      closeModal('createCase')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn])

  useEffect(() => {
    ;(async () => {
      if (resourceRef.current) {
        createCase(resourceRef.current, device)
        resourceRef.current = null
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.DeviceId])

  const createCase = (resource, device) => {
    const DeviceId = device?.DeviceId
    if (DeviceId) {
      if (resource?.serviceType == 3)
        environment.isMobile
          ? router(
              navigation,
              device?.DeviceId,
              'speaktoagent',
              {
                device,
                resource
              },
              navigatePath
            )
          : setShow(true)
      else {
        openCreateCase({
          device: { ...device, DeviceId },
          resource
        })
      }
    } else {
      if (!device?.IsSubscriptionDevice)
        addDeviceToProfile({ device, resource })
    }
    setLoader(false)
  }

  const launcher = useCallback(async (cmd, device) => {
    setLoader(false)
    await launchUrlFromCommand(cmd, device)
  }, [])
  const hpSmartlauncher = useCallback(async () => {
    setLoader(false)
    await launchHPSmart()
  }, [])
  const contactLauncher = useCallback(
    async (resource, device, isCheckOptin = true) => {
      setResource({ ...resource, show: true })
      setShow(false)
      if (
        OPTIN_RESOURCES.includes(resource.serviceType) &&
        device?.IsSubscriptionDevice == false
      ) {
        const isManagedConsent = await managedConsent()
        if (isManagedConsent && !optin) {
          await showPrivacySettingsDialog()
          return
        } else if (!optin && isCheckOptin) {
          return showOptinConfirm(
            () => contactLauncher(resource, device, false),
            device
          )
        }
      }

      if (resource.openStatus?.toLowerCase() == 'false') {
        openSupportHour({ resource })
        return
      }

      if (resource.serviceType == 60) {
        const smode = await deviceInfo.getSmode()
        if (smode) return launcher(OMNI_CHAT_CMD, device)
        if (await isFileExist('ChatRunning.txt'))
          return createProcess('/OCChat /Reactive', false, false, false)
      }

      if (resource?.signInRequired == 1) {
        const auth = await setAuthToken()
        if (!auth) {
          setLoader(false)
          return
        } else {
          MethoneWinClient.setAuth(auth)
          MethoneClient.auth = auth
        }
      }

      setLoader(true)
      if (
        (!resource.isEmbedded || resource.isEmbedded == 0) &&
        resource.serviceType != 55
      ) {
        return launcher(resource.cmd, device)
      }

      switch (resource.serviceType) {
        case 3:
        case 60:
          {
            if (resource?.signInRequired == 0) return
            const DeviceId = device?.DeviceId
            if (DeviceId || DeviceId === 0) {
              createCase(resource, device)
            } else {
              resourceRef.current = resource
              return
            }
          }
          break
        case 55:
          {
            const request: ICacheUserRequest = {
              serialNumber: device.SerialNumber,
              productNumber: device.ProductNumber.split('#')[0]
            }
            const cacheUser: ICacheUserResponse =
              await MethoneClient.createCacheUser(request)
            if (cacheUser?.CacheToken) {
              const param = {
                country:
                  device?.DeviceInfo?.locale?.country || localization?.country,
                language:
                  device?.DeviceInfo?.locale?.language ||
                  localization?.language,
                cacheToken: cacheUser?.CacheToken
              }
              const languages = await localization.getLanguages()
              const isLocaleMatch = languages?.[param?.language]?.includes(
                param?.country?.toLowerCase()
              )
              const callmeBackUrl = Config[stack]?.CALL_ME({
                ...param,
                isLocaleMatch,
                country: isLocaleMatch ? param.country : localization?.country
              })
              launcher(callmeBackUrl, device)
            }
          }
          break
        case 'HPSmart':
          {
            console.log('HPSmart')
            hpSmartlauncher()
          }
          break
        default:
          launcher(resource.cmd, device)
      }
      setLoader(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsSubmitted, authProvider, optinProvider, themeProvider, environment]
  )

  const addDeviceToProfile = ({ device, resource }) => {
    openDialog({
      title: t(
        'common.addDeviceToHPAccount',
        'Add this device to your HP account'
      ),
      content: t(
        'common.addDeviceByClickTheButton',
        'For the most optimal Support experience, your device must be added into your HP account. By clicking the Yes button below will allow you to connect with support agents directly and to keep track of your assigned support cases.'
      ),
      align: left({ theme: { dir } }),
      okCallback: async () => {
        closeDialog()
        const { DeviceId, cancelled } = await addDevice(device)
        if (DeviceId && cancelled === false) {
          if (resource.serviceType == 3)
            environment.isMobile
              ? router(
                  navigation,
                  DeviceId,
                  'speaktoagent',
                  {
                    device,
                    resource
                  },
                  navigatePath
                )
              : setShow(true)
          else
            openCreateCase({
              device: { ...device, DeviceId },
              resource
            })
        }
      },
      okText: t('common.yes', 'Yes'),
      cancelCallback: () => {
        closeDialog()
      },
      cancelText: t('common.no', 'No')
    })
  }

  const openCreateCase = async ({ device, resource }) => {
    const createCase = {
      isOpen: true,
      modalTitle: t('common.myHPChat1'),
      category: {
        contextTitle: t('common.chatWithAgent'),
        type: 'createCase'
      },
      hide: false,
      resize: false,
      sessionStatus: false,
      loading: true,
      component: CreateCase,
      props: {
        environment,
        themeProvider,
        refreshProvider,
        authProvider,
        resource,
        device,
        closeModal,
        setIsSubmitted,
        isSubmitted,
        setLoading,
        MethoneClient,
        formatAutoId
      }
    }

    environment.isMobile
      ? router(
          navigation,
          device?.DeviceId,
          'chatwithagent',
          {
            device,
            resource
          },
          navigatePath
        )
      : openModal({ createCase })
  }

  const showOptinConfirm = (callback, device) => {
    optinConfirm({
      okCallback: () => {
        closeDialog()
        setSupportConsent(true)
        if (!device?.IsSubscriptionDevice) {
          getWarrantyInfo(
            device?.SerialNumber,
            device?.ProductNumber,
            device?.DeviceInfo?.locale?.language,
            device?.DeviceInfo?.locale?.country,
            true
          )
        }
        callback?.()
      },
      device
    })
  }

  const openSupportHour = ({ resource }) => {
    const supportHours = {
      ...modalConfig,
      modalTitle: t('common.myHPOutsideOfHour'),
      category: {
        contextTitle: t(
          'common.supportCenterOperatingHours',
          'Support center operating hours'
        ),
        type: 'supportHours'
      },
      width: '487px',
      background: true,
      component: SupportHour,
      props: { resource, useModal }
    }
    openModal({ supportHours })
  }

  return {
    contactLauncher,
    resource,
    contactMap,
    show,
    setShow,
    loader,
    launcher
  }
}

export default useLauncher
