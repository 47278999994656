import React, { useContext } from 'react'
import RootProvider from '@jarvis/react-hpx-support-shared/dist/context/root/RootProvider'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import Button from '@veneer/core/dist/scripts/button'
import { Section } from '../SupportResources/styles'
import resources from '../../assets/locale'
import { ShellProps } from '../../types/shell'
import { LocalizationType } from '../../types/localization'
import { genTestId } from '../../redux/support/utils'
import { JarvisAnalyticsContextProvider } from '@jarvis/jweb-analytics'
import { withAnalytics } from '@veneer/analytics'
import {
  DeviceScreenName,
  DefaultScreenName,
  TestIDPrefix,
  ComponentSupportResourcesName
} from '../../redux/support/constants'

const TrackButton = withAnalytics(Button)
interface IModal {
  modal: unknown
  openModal: (modal) => void
  setResize: (resize: boolean) => void
  closeModal: (isOpen: string) => void
}
interface IContext {
  useModal?: () => IModal
  localization?: LocalizationType
}

const SupportHour = ({ resource }) => {
  const { t } = useI18n()
  const { useModal } = useContext<IContext>(RootContext)
  const { closeModal } = useModal()
  return (
    <Section className="m-b-28">
      <span className="m-b-4">
        <b>{resource.whDesc}</b>
      </span>
      <span className="m-b-40">
        {t(
          'common.supportCenterIsClosedPleaseTryAgain',
          'We are sorry, The support center is closed at this time. Please try again during the hours listed above.'
        )}
      </span>
      <TrackButton
        trackId={genTestId(TestIDPrefix, 'OutofWorkingHours')}
        trackCategory={`${ComponentSupportResourcesName}`}
        appearance="secondary"
        onClick={() => {
          closeModal('supportHours')
        }}
      >
        {t('common.close', 'Close')}
      </TrackButton>
    </Section>
  )
}

declare global {
  interface Window {
    Shell: ShellProps
  }
}

const SupportHoursProvider = (props) => {
  const { v1 } = window?.Shell as ShellProps
  return (
    <JarvisAnalyticsContextProvider
      initialScreenData={{
        screenName: DefaultScreenName,
        activity: `${DeviceScreenName}-v01`
      }}
    >
      <RootProvider
        localization={v1.localization}
        resources={resources}
        useModal={props.useModal}
        MethoneClient={props.MethoneClient}
        store={v1.store}
      >
        <SupportHour resource={props.resource}></SupportHour>
      </RootProvider>
    </JarvisAnalyticsContextProvider>
  )
}

export default SupportHoursProvider
