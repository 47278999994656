import { createGlobalStyle, css } from 'styled-components'

const breakpoints = {
  L: '1008px',
  M: '640px',
  S: '500px'
}

const rtl =
  (left, right) =>
  ({ theme }) =>
    theme.dir == 'rtl' ? right : left
export const left = rtl('left', 'right')
export const right = rtl('right', 'left')

const HPXTheme = {
  breakpoints,
  mediaQueries: {
    L: `@media only screen and (min-width: ${breakpoints.L})`,
    M: `@media only screen and (max-width: 1007px)`,
    S: `@media only screen and (max-width: ${breakpoints.M})`,
    XS: `@media only screen and (max-width: ${breakpoints.S})`
  }
}

export default HPXTheme

export const DropdownStyle = createGlobalStyle`
  ${() => {
    return css`
      [data-popper-placement^='top'] {
        > div {
          border-radius: 12px 12px 0px 0px;
          margin: 0px;
          .vn-option.disabled {
            display: none;
          }
        }
      }
      [data-popper-placement^='bottom'] {
        > div {
          border-radius: 0px 0px 12px 12px;
          margin: 0px;
          .vn-option.disabled {
            display: none;
          }
        }
      }
    `
  }}
`
