/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { Box, Description, Col, FlexRow } from '../SupportResources/styles'
import useCases from '../../hooks/useCases'
import useContextAPI from '../../hooks/useContextAPI'
import useMethoneClient from '../../api/methone'
import CreateCase from '../CreateCase'

export default function SpeakToAgent({ show, setShow, resource, device }) {
  const { t } = useI18n()
  const { MethoneClient } = useMethoneClient()
  const {
    formatAutoId,
    authProvider,
    refreshProvider,
    themeProvider,
    environment
  } = useContextAPI()
  const myRef = useRef(null)

  useEffect(() => {
    if (show)
      setTimeout(
        () =>
          myRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          }),
        500
      )
  }, [show])

  const { isSubmitted, setIsSubmitted } = useCases()

  return (
    <div ref={myRef}>
      <Box
        className="speak-to-agent no-pointer cardStyle"
        show={true}
        data-testid="support-resources-card"
        appearance="outlined"
        content={
          <>
            {/* <FloatingLink onClick={() => setShow(false)}>
            <IconChevronUp
              onClick={() => setShow(false)}
              color="colorHpBlue6"
              size={20}
            ></IconChevronUp>
          </FloatingLink> */}
            <FlexRow L={2} M={1} S={1}>
              <Col size={6} className="col-md-12 m-r-40">
                {/* <Title className="body-large">Phone</Title> */}
                {/* <SubTitle className="body">Call an HP Tech specialist.</SubTitle> */}
                {!device?.IsSubscriptionDevice && (
                  <Description className="caption">
                    {t('speakToAgent.callHPTechDetail')}
                  </Description>
                )}
                {!isSubmitted && (
                  <Description className="caption">
                    {t('common.createCaseDescription')}
                  </Description>
                )}
              </Col>
              {resource?.serviceId == 'Call_HP' && (
                <Col size={6} className="col-md-12 m-l-40">
                  <CreateCase
                    resource={resource}
                    closeModal={setShow}
                    device={device}
                    setIsSubmitted={setIsSubmitted}
                    MethoneClient={MethoneClient}
                    refreshProvider={refreshProvider}
                    authProvider={authProvider}
                    themeProvider={themeProvider}
                    formatAutoId={formatAutoId}
                    setLoading={null}
                    modal={null}
                    environment={environment}
                  ></CreateCase>
                </Col>
              )}
            </FlexRow>
          </>
        }
      ></Box>
    </div>
  )
}
