import { useState } from 'react'

const useCases = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  return {
    isSubmitted,
    setIsSubmitted
  }
}

// const caseData = {
//   FaultItemList: [],
//   Case: {
//     CaseID: '5150161961',
//     Description: 'My PC has a red light coming from display',
//     CaptionOpen: 'Your case was started',
//     CaptionClose: 'Your case was closed',
//     Status: 0,
//     StatusText: 'Open',
//     CreatedDateTime: '2019-04-23',
//     DateClosed: '',
//     ProductName: 'HP ZBook 15 G2',
//     ProductNumber: 'Y3R94UC',
//     SerialNumber: 'CND5252BVP',
//     Nickname: 'Living room printer',
//     ServiceOrders: []
//   }
// }

export default useCases
