import {
  IDeviceRegisterRequest,
  IDeviceRegisterResponse
} from '@jarvis/web-support-commons/dist/methone/devices/types/device'
import useMethoneClient from '../api/methone'
import useContextAPI from './useContextAPI'
import AddingDevice from '../components/AddingDevice'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { useRef } from 'react'

const useAddDevice = () => {
  const { MethoneClient, MethoneWinClient } = useMethoneClient()
  const { loading, setLoading, useModal, updateDeviceListBySN, featureFlag } =
    useContextAPI()
  const { isSupportHomeEnabled, deviceListEnabled } = featureFlag
  const { openModal, closeModal } = useModal()
  const cancelRef = useRef(false)
  const { t } = useI18n()
  const controller = new AbortController()

  async function addDevice(device) {
    try {
      cancelRef.current = false
      openAddingDeviceModal()
      const deviceRegisterRequest: IDeviceRegisterRequest = {
        NickName: device.NickName || 'My PC',
        ProductNumber: device.ProductNumber.split('#')[0],
        SerialNumber: device.SerialNumber,
        UUID: device.UUID,
        CountryOfPurchase: ''
      }
      const deviceRegister: IDeviceRegisterResponse =
        await MethoneClient.deviceRegister(deviceRegisterRequest, controller)
      updateDeviceListBySN({
        DeviceId: deviceRegister?.DeviceId,
        SerialNumber: device?.SerialNumber
      })
      if (isSupportHomeEnabled === true) {
        await MethoneWinClient?.refershDevices?.(deviceListEnabled, 3)
      }
      return {
        DeviceId: deviceRegister?.DeviceId,
        cancelled: cancelRef.current
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
    } finally {
      closeModal('addingDevice')
    }
  }

  const cancelCallback = () => {
    cancelRef.current = true
    closeModal('addingDevice')
  }

  function openAddingDeviceModal() {
    const addingDevice = {
      isOpen: true,
      modalTitle: t('common.addADevice', 'Add a device'),
      width: '688px',
      category: {
        type: 'addDevice'
      },
      hide: false,
      resize: false,
      sessionStatus: false,
      closeDisable: true,
      closeCallback: () => closeModal('addingDevice'),
      component: AddingDevice({ t, cancelCallback })
    }
    openModal({ addingDevice })
  }

  return {
    loading,
    addDevice,
    setLoading
  }
}

export default useAddDevice
