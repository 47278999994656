import React from 'react'
import store from '../src/redux'
import { Provider } from 'react-redux'
import { ShellProps } from '../src/types/shell'
import { RootComponent } from '../src/components'
import { ThemeProvider } from '@veneer/theme'
import RootProvider from '@jarvis/react-hpx-support-shared/dist/context/root/RootProvider'
import resources from '../src/assets/locale'
import { DirectionProvider } from '@veneer/core/dist/scripts/direction'
import { JarvisAnalyticsContextProvider } from '@jarvis/jweb-analytics'
import {
  BaseScreenPath,
  DeviceScreenName,
  DefaultScreenName,
  SupportDevice,
  Support
} from '../src/redux/support/constants'
declare global {
  interface Window {
    Shell: ShellProps
  }
}

export default function Root({ ...props }) {
  const { v1 } = window.Shell as ShellProps
  const namespace = '@jarvis/react-hpx-support'
  const theme = props?.themeProvider || {}

  return (
    <section id={namespace}>
      <JarvisAnalyticsContextProvider
        initialScreenData={{
          screenPath: `${BaseScreenPath}/${Support}/${SupportDevice}/`,
          screenName: DefaultScreenName,
          activity: `${DeviceScreenName}-v01`
        }}
      >
        <DirectionProvider direction={theme.dir}>
          <ThemeProvider {...theme}>
            <RootProvider
              localization={v1.localization}
              resources={resources}
              useModal={props.useModal}
              MethoneClient={props.MethoneClient}
              MethoneWinClient={props.MethoneWinClient}
              formatAutoId={props?.formatAutoId}
              store={v1.store}
              openDialog={props.openDialog}
              closeDialog={props.closeDialog}
              sharedProvider={props.sharedProvider}
              authProvider={props.authProvider}
              refreshProvider={props.refreshProvider}
              updateDeviceListBySN={props?.updateDeviceListBySN}
              getWarrantyInfo={props?.getWarrantyInfo}
              optinProvider={props.optinProvider}
              dir={theme.dir}
              navigation={v1.navigation}
              featureFlag={props.featureFlag}
              environment={props.environment}
              themeProvider={theme}
              navigatePath={props?.navigatePath}
            >
              <Provider store={store}>
                <RootComponent
                  stack={props.stack}
                  device={props.device}
                  Skelton={props.Skelton}
                  onComplete={props.onComplete}
                  type={props.type}
                  navigatePath={props?.navigatePath}
                />
              </Provider>
            </RootProvider>
          </ThemeProvider>
        </DirectionProvider>
      </JarvisAnalyticsContextProvider>
    </section>
  )
}
