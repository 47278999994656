/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import MicroFrontend from '@jarvis/react-hpx-support-shared/dist/components/MFELoader/MicroFrontend'
import React from 'react'

const lazyLoad = (component) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  React.lazy(() => System.import(component))

const MFE_REFS = {
  CASE_CREATE: lazyLoad('@jarvis/react-hpx-case-create')
}

const CreateCase = ({
  resource,
  device,
  closeModal,
  setIsSubmitted,
  MethoneClient,
  refreshProvider,
  themeProvider,
  authProvider,
  formatAutoId,
  setLoading,
  modal,
  environment
}) => {
  const CreateCaseWidget = useMemo(
    () =>
      resource &&
      device && (
        <MicroFrontend
          card={true}
          component={MFE_REFS.CASE_CREATE}
          data-testid="hpx-support-case-create-mfe"
          header={false}
          resource={resource}
          setOpenChat={closeModal}
          device={device}
          setIsSubmitted={setIsSubmitted}
          refreshProvider={refreshProvider}
          MethoneClient={MethoneClient}
          authProvider={authProvider}
          themeProvider={themeProvider}
          formatAutoId={formatAutoId}
          environment={environment}
          setLoading={setLoading}
          modal={modal}
          Skelton={null}
        />
      ),
    [resource, device, authProvider?.profile, themeProvider?.mode]
  )

  return <>{CreateCaseWidget}</>
}

export default CreateCase
