import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import { Row, Column, Section, Spinner } from '../SupportResources/styles'

const AddingDevice = ({ t, cancelCallback }) => {
  return (
    <>
      <Section className="size">
        {/*<Row>
          <Button
            customStyle={{ borderRadius: '0px' }}
            leadingIcon={<IconArrowLeft />}
          />
          <span style={{ flex: 1 }} className="body-large align-center m-l-8">
            Add a device
          </span>
          <Button appearance="ghost" leadingIcon={<IconX />} />
        </Row>*/}
        <Row className="m-t-32 full">
          <Column>
            <Row className="full">
              <Column align="center">
                <Row>
                  <Spinner appearance="circular" behavior="indeterminate" />
                </Row>
                <Row className="m-t-36">
                  <span className="title-small">
                    {t('common.addingYourDevice', 'Adding your device')}
                  </span>
                </Row>
                <Row className="m-t-12">
                  <span className="caption-small">
                    {t(
                      'common.thisMayTakeUpTo60s',
                      'This may take up to 60 seconds. Please wait.'
                    )}
                  </span>
                </Row>
              </Column>
            </Row>
            <Row>
              <Column align="flex-end">
                <Row className="m-t-8 m-b-40 m-t-36">
                  <Button
                    appearance="secondary"
                    disabled={true}
                    onClick={() => {
                      cancelCallback()
                    }}
                  >
                    {t('common.cancel', 'Cancel')}
                  </Button>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
      </Section>
    </>
  )
}

export default AddingDevice
