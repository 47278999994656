import IconChat from '@veneer/core/dist/scripts/icons/icon_chat'
import IconPin from '@veneer/core/dist/scripts/icons/icon_pin'
import IconDocument from '@veneer/core/dist/scripts/icons/icon_document'
import IconGlobe from '@veneer/core/dist/scripts/icons/icon_globe'
import IconPhone from '@veneer/core/dist/scripts/icons/icon_phone'
import IconWrench from '@veneer/core/dist/scripts/icons/icon_wrench'
import IconPeople from '@veneer/core/dist/scripts/icons/icon_people'
import IconTools from '@veneer/core/dist/scripts/icons/icon_tools'
import IconSmartApp from '@veneer/core/dist/scripts/icons/icon_smart_app'

import {
  fbm,
  wechat,
  whatsapp,
  whatsapp_M,
  twitter,
  kalkotalk,
  line,
  zalo,
  SubLogo,
  VA,
  normalRadio,
  selectRadio
} from '../../../assets/images'
import { Image } from '../styles'
import React from 'react'

const ResourceIcon = ({ size = 20, color, type }) => {
  const contactMap = {
    Chat: <IconChat className="icon-color" size={size} color={color} />,
    ChatWithExpert: (
      <IconChat className="icon-color" size={size} color={color} />
    ),
    ProductPage: <IconGlobe className="icon-color" size={size} color={color} />,
    Forum: <IconPeople className="icon-color" size={size} color={color} />,
    WarrantyDispute: (
      <IconGlobe className="icon-color" size={size} color={color} />
    ),
    ServiceCenter: <IconPin className="icon-color" size={size} color={color} />,
    VirtualRepairCenter: (
      <IconTools className="icon-color" size={size} color={color} />
    ),
    Call_HP: <IconPhone size={size} color={color} />,
    ProductManual: (
      <IconDocument className="icon-color" size={size} color={color} />
    ),
    WhatsApp: <Image src={whatsapp} size={size} color={color} />,
    WhatsApp_M: <Image src={whatsapp_M} size={size} color={color} />,
    FBMessenger: <Image src={fbm} size={size} color={color} />,
    WeChat: <Image src={wechat} size={size} color={color} />,
    Twitter: <Image src={twitter} size={size} color={color} />,
    Metalocator: (
      <IconWrench className="icon-color" size={size} color={color} />
    ),
    KakaoTalk: <Image src={kalkotalk} size={size} color={color} />,
    Line: <Image src={line} size={size} color={color} />,
    Zalo: <Image src={zalo} size={size} color={color} />,
    SubLogo: <Image src={SubLogo} size={size} color={color} />,
    VA: <Image src={VA} size={size} color={color} />,
    normalRadio: <Image src={normalRadio} size={size} color={color} />,
    selectRadio: <Image src={selectRadio} size={size} color={color} />,
    eService: <IconWrench className="icon-color" size={size} color={color} />,
    HowToDixon: <IconPeople className="icon-color" size={size} color={color} />,
    HPSmart: <IconSmartApp className="icon-color" size={size} color={color} />,
    default: <IconGlobe className="icon-color" size={size} color={color} />
  }

  return <>{contactMap[type] || contactMap.default}</>
}

export default ResourceIcon
