/* eslint-disable react-hooks/exhaustive-deps */
import SupportComponent from './SupportResources'
import { ThemeProvider as DirectionProvider } from 'styled-components'
import React, { useEffect } from 'react'
import { useTheme } from '@veneer/theme'
import useContextAPI from '../hooks/useContextAPI'
const Support = React.lazy(() => import('../mobile/index'))

export const RootComponent = (props) => {
  const theme = useTheme()
  const { environment, dir } = useContextAPI()

  useEffect(() => {
    props?.onComplete?.()
  }, [])

  return (
    <DirectionProvider theme={{ ...environment, ...theme, dir }}>
      {environment.isMobile ? (
        <Support
          type={props.type}
          stack={props.stack}
          device={props.device}
          Skelton={props.Skelton}
        ></Support>
      ) : (
        <SupportComponent
          stack={props.stack}
          device={props.device}
          Skelton={props.Skelton}
        ></SupportComponent>
      )}
    </DirectionProvider>
  )
}
