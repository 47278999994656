import React from 'react'
import {
  Column,
  ContentDescription,
  ContentTitle,
  Row,
  Image,
  Col,
  Box,
  ProgressBar,
  ImageLoader
} from '../styles'
import useContextAPI from '../../../hooks/useContextAPI'
import useImage from '../../../hooks/useImage'
import useDebounce from '@jarvis/react-hpx-support-shared/dist/hooks/useDebounce'
import { genTestId } from '../../../redux/support/utils'
import {
  TestIDPrefix,
  ComponentLiveAssistanceName,
  ComponentSupportResourcesName
} from '../../../redux/support/constants'

const component = 'LiveAssistance'

const LiveAssistance = ({
  contact,
  resource,
  index,
  device,
  show,
  loader,
  contactLauncher,
  disabled = false
}) => {
  const {
    environment: { isMobile },
    authProvider,
    formatAutoId
  } = useContextAPI()
  const { call, chat, onError, speak, onLoad, loading } = useImage()
  const { isSignedIn } = authProvider
  const isWrongAccount =
    device?.IsSubscriptionDevice &&
    isSignedIn &&
    (device?.DeviceId == 0 || device?.DeviceId == undefined)

  const handleClick = useDebounce(() => {
    if (disabled || (contact?.serviceType == 3 && show)) return
    if (
      contact.serviceType == '3' &&
      device?.SupportCode?.includes('20') &&
      (!isSignedIn || device?.DeviceId == 0 || device?.DeviceId == undefined)
    )
      return
    contactLauncher(contact, device)
  }, 500)

  return contact?.serviceType == 60 && isWrongAccount ? (
    <></>
  ) : (
    <Col
      className={`assistance`}
      reorder={index == 2 && contact?.serviceType != 55}
      size={4}
      key={index}
    >
      <Box
        role="button"
        background={false}
        disabled={disabled}
        className="flexStart cardStyle"
        show={contact.serviceType === resource?.serviceType && show}
        padding="0px"
        id={formatAutoId(`${component}_card_${contact.serviceId}`)}
        trackId={genTestId(
          `${TestIDPrefix}_${ComponentLiveAssistanceName}`,
          contact?.serviceId?.toLowerCase()
        )}
        trackCategory={`${ComponentSupportResourcesName}`}
        hoverable={false}
        appearance="outlined"
        tabindex={0}
        onClick={handleClick}
        aria-labelledby={`${formatAutoId(
          `${component}_title_${contact.serviceId}`
        )} ${formatAutoId(`${component}_desc_${contact.serviceId}`)}`}
        title={
          [70, 80, 90, 100].includes(device?.ProductType) &&
          contact.serviceType == '3' &&
          contact.whDesc
        }
        content={
          <>
            {loader && contact.serviceType === resource?.serviceType && (
              <ProgressBar appearance="circular"></ProgressBar>
            )}
            <Column>
              <Row>
                {loading && <ImageLoader />}
                {contact.serviceType == '3' && (
                  <Image
                    minWidth={true}
                    style={{ display: loading ? 'none' : '' }}
                    onLoad={onLoad}
                    onError={onError}
                    src={speak}
                  />
                )}
                {contact.serviceType == '55' && (
                  <Image
                    minWidth={true}
                    style={{ display: loading ? 'none' : '' }}
                    onLoad={onLoad}
                    onError={onError}
                    src={call}
                  />
                )}
                {contact.serviceType == '60' && (
                  <Image
                    minWidth={true}
                    style={{ display: loading ? 'none' : '' }}
                    onLoad={onLoad}
                    onError={onError}
                    src={chat}
                  />
                )}
              </Row>
              <Row>
                <Column className="m-12 m-b-0">
                  <ContentTitle
                    className={isMobile ? 'Subheadline' : 'label'}
                    id={formatAutoId(`${component}_title_${contact.serviceId}`)}
                  >
                    {contact.serviceTitle}
                    {contact.serviceType == '3' &&
                      device?.SupportCode?.includes('20') &&
                      (!isSignedIn ||
                        device?.DeviceId == 0 ||
                        device?.DeviceId == undefined) &&
                      contact?.PhoneGroups?.map((phone, index) => {
                        return (
                          <>
                            {index === 0 && <>&nbsp;</>}
                            {phone.Prefix && <>{phone.Prefix}</>} {phone.Phone}
                            {index != contact?.PhoneGroups?.length - 1 && (
                              <>,&nbsp;</>
                            )}
                          </>
                        )
                      })}
                  </ContentTitle>
                  {contact.serviceType == '3' &&
                  device?.SupportCode?.includes('20') &&
                  (!isSignedIn ||
                    device?.DeviceId == 0 ||
                    device?.DeviceId == undefined) ? (
                    <ContentDescription
                      className={isMobile ? 'Caption' : 'caption-small'}
                      id={formatAutoId(
                        `${component}_desc_${contact.serviceId}`
                      )}
                      dangerouslySetInnerHTML={{
                        __html:
                          contact.openStatus?.toLowerCase() == 'false'
                            ? contact.serviceDescClosed
                            : contact.whDesc
                      }}
                    ></ContentDescription>
                  ) : (
                    <>
                      <ContentDescription
                        className={isMobile ? 'Caption' : 'caption-small'}
                        id={formatAutoId(
                          `${component}_desc_${contact.serviceId}`
                        )}
                        dangerouslySetInnerHTML={{
                          __html:
                            contact.openStatus?.toLowerCase() == 'false'
                              ? contact.serviceDescClosed
                              : contact.serviceDesc
                        }}
                      ></ContentDescription>
                    </>
                  )}
                </Column>
              </Row>
            </Column>
          </>
        }
      ></Box>
    </Col>
  )
}

export default LiveAssistance
