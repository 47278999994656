/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import { CountrySelector, Select, DropDownTitle } from '../../styles'
import useContextAPI from '../../../../hooks/useContextAPI'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { genTestId } from '../../../../redux/support/utils'
import {
  TestIDPrefix,
  ComponentSupportResourcesName
} from '../../../../redux/support/constants'
import useTheme from '@veneer/theme/dist/use_theme'
import { DropdownStyle } from '../../../../styles/global'

TestIDPrefix

const maxItemShow = 8

const CountryDropdown = ({ setCountry, country, countries, Skelton }) => {
  const { formatAutoId, dir } = useContextAPI()
  const theme = useTheme()
  const [options, setOptions] = useState<any[]>([])
  const [selProps, setSelProps] = useState({
    visibleOptions: maxItemShow,
    placement: `bottom-${dir == 'rtl' ? 'start' : 'end'}`
  })

  console.log(`Country inside is ${country}`)
  const selectRef = useRef(null)
  const { t } = useI18n()

  const onChange = (selectedOption) => {
    return setCountry([selectedOption.value])
  }
  const onSearch = (value) => {
    if (value)
      setOptions(
        countries.filter((e) =>
          e.label.toLowerCase().startsWith(value.toLowerCase())
        )
      )
    else {
      setOptions(countries)
    }
    edgeFixSelect()
  }

  useEffect(() => {
    ;(async () => {
      setOptions(countries)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries])

  useEffect(() => {
    const setSelectView = () => {
      try {
        if (selectRef.current) {
          const nodeElem = selectRef.current as HTMLElement
          const domRect = nodeElem?.getBoundingClientRect()
          const body = document.body.getBoundingClientRect()
          const bottom = body.bottom - domRect.bottom
          const top = domRect.top
          if (bottom > 200 || bottom > top) {
            const showCount = bottom / 60
            setSelProps({
              visibleOptions:
                showCount >= maxItemShow || showCount <= 0
                  ? maxItemShow
                  : showCount,
              placement: `bottom-${dir == 'rtl' ? 'start' : 'end'}`
            })
          } else {
            const showCount = top / 60
            setSelProps({
              visibleOptions:
                showCount >= maxItemShow || showCount <= 0
                  ? maxItemShow
                  : showCount,
              placement: `top-${dir == 'rtl' ? 'start' : 'end'}`
            })
            edgeFixSelect()
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    window.addEventListener('resize', setSelectView)
    setSelectView()

    return () => {
      window.removeEventListener('resize', setSelectView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true }

    // Callback function to execute when mutations are observed
    const callback = function (mutationsList) {
      // Use traditional 'for loops' for IE 11
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          if (mutation.target.id === '') {
            edgeFixSelect()
          }
        }
      }
    }

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(document.body, config)

    // Later, you can stop observing
    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // EDGE - select list not visible sometimes on search
  function edgeFixSelect() {
    setTimeout(() => {
      const popover: HTMLElement = document.querySelector(
        `[data-popper-placement="${selProps?.placement}"]`
      )
      if (popover) {
        popover.style.background = theme?.colorBackgroundContainer
        popover.style.borderRadius = '12px'
        const popoverChild: HTMLElement =
          popover.querySelector('div:first-child')
        if (popoverChild) popoverChild.style.margin = '0px'
      }
    })
  }

  return (
    <CountrySelector>
      <DropdownStyle />
      <DropDownTitle className="caption-small">
        {t('common.supportByRegion', 'Support by region')}
      </DropDownTitle>
      {countries?.length != 0 ? (
        <div ref={selectRef}>
          <Select
            options={options}
            id={formatAutoId(`support-resoucre-country`)}
            trackId={genTestId(TestIDPrefix, 'CountryListDropDown')}
            trackCategory={`${ComponentSupportResourcesName}`}
            label=""
            clearIcon={false}
            visibleOptions={selProps?.visibleOptions}
            showSearch={true}
            helperTextVisibility="auto"
            onChange={onChange}
            value={country}
            placement={selProps?.placement}
            onSearch={onSearch}
            i18n={{
              clear: t('common.clearTxt', 'Clear'),
              noResults: t('common.noResultsTxt', 'No results found'),
              open: '',
              searchPlaceholder: t(
                'common.searchPlaceholderTxt',
                'Search Items'
              ),
              selected: t('common.selectedTxt', 'Selected'),
              showingResult: t('common.showingResultTxt', 'Showing %s result'),
              showingResults: t(
                'common.showingResultsTxt',
                'Showing %s results'
              ),
              unselected: t('common.unselectedTxt', 'Unselected')
            }}
          ></Select>
        </div>
      ) : (
        <div className="p-l-12">
          <Skelton circle={true} height={24} width={24}></Skelton>
        </div>
      )}
    </CountrySelector>
  )
}

export default React.memo(CountryDropdown)
