import React, { memo, Suspense, useCallback } from 'react'
import {
  ItemContent,
  ItemDescription,
  ItemTitle,
  FlexRow,
  Col,
  Box,
  IconContainer
} from '../styles'
import useContextAPI from '../../../hooks/useContextAPI'
import { ProductIconPlaceholder } from '../ResourceIcon/style'
import { genTestId } from '../../../redux/support/utils'
import {
  TestIDPrefix,
  ComponentSupportResourcesName
} from '../../../redux/support/constants'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'

const component = 'Resources'

const ResourceIconLazy = React.lazy(() => import('../ResourceIcon'))

const ResourceIcon = ({ size, color, type }) => {
  return (
    <Suspense
      fallback={
        <ProductIconPlaceholder single={true} height={size} width={size} />
      }
    >
      <ResourceIconLazy size={size} color={color} type={type} />
    </Suspense>
  )
}

const ListView = ({
  resources,
  device,
  contactLauncher,
  className = '',
  flex,
  description = true,
  type,
  analyticsTitle = ''
}) => {
  const {
    environment: { isMobile },
    formatAutoId
  } = useContextAPI()
  const { t } = useI18n()

  const IsSubscriptionPrinter = useCallback(() => {
    return (
      device?.ProductType === 1 &&
      device?.IsSubscriptionDevice &&
      device?.IsSubscriptionActive
    )
  }, [
    device?.IsSubscriptionDevice,
    device?.IsSubscriptionActive,
    device?.ProductType
  ])

  return (
    <>
      <FlexRow
        gap={isMobile && type == 'grid' ? '1px' : undefined}
        M={2}
        S={1}
        type={type}
      >
        {resources &&
          resources.map(
            (resource, index) =>
              resource && (
                <Col size={flex} className={className} key={resource.serviceId}>
                  <Box
                    type={type}
                    first={index == 0}
                    end={index == resources.length - 1}
                    role="button"
                    className="cardStyle"
                    tabindex={0}
                    trackId={genTestId(
                      `${TestIDPrefix}_${analyticsTitle}`,
                      resource?.serviceId?.toLowerCase()
                    )}
                    trackCategory={`${ComponentSupportResourcesName}`}
                    id={formatAutoId(`${component}_Card_${resource.serviceId}`)}
                    appearance="outlined"
                    hoverable={true}
                    onClick={() => {
                      contactLauncher(resource, device)
                    }}
                    title={resource.serviceDesc}
                    aria-labelledby={`${formatAutoId(
                      `${component}_Title_${resource.serviceId}`
                    )} ${formatAutoId(
                      `${component}_Desc_${resource.serviceId}`
                    )}`}
                    content={
                      <>
                        <IconContainer type={resource.serviceId}>
                          <ResourceIcon
                            type={resource.serviceId}
                            size={32}
                            color="colorGray10"
                          ></ResourceIcon>
                        </IconContainer>
                        <ItemContent type={resource.serviceId}>
                          <ItemTitle
                            className={isMobile ? 'Caption' : 'label'}
                            id={formatAutoId(
                              `${component}_Title_${resource.serviceId}`
                            )}
                          >
                            {resource.serviceTitle?.capitalize()}
                          </ItemTitle>
                          {description && (
                            <ItemDescription
                              className="caption-small"
                              id={formatAutoId(
                                `${component}_Desc_${resource.serviceId}`
                              )}
                            >
                              {resource.serviceDesc}
                            </ItemDescription>
                          )}
                        </ItemContent>
                      </>
                    }
                  ></Box>
                </Col>
              )
          )}
      </FlexRow>
      {IsSubscriptionPrinter() && className === 'resources' && (
        <FlexRow M={1} S={1} column={1} marginTop={16} type={'grid'}>
          <Col size={12} flex={1} className={className}>
            <Box
              type={type}
              role="button"
              className="cardStyle"
              tabindex={0}
              appearance="outlined"
              hoverable={true}
              id={formatAutoId(`${component}_Card_${'HPSmart'}`)}
              title={t('common.accessAccountInformation')}
              aria-labelledby={`${formatAutoId(
                `${component}_Title_HPSmart`
              )} ${formatAutoId(`${component}_Desc_HPSmart`)}`}
              trackId={genTestId(
                `${TestIDPrefix}_${analyticsTitle}`,
                'openTheHPSmartApp'
              )}
              onClick={() => {
                contactLauncher(
                  {
                    serviceId: 'HPSmart',
                    serviceType: 'HPSmart',
                    serviceDesc: t('common.openTheHPSmartApp'),
                    isEmbedded: 1
                  },
                  device
                )
              }}
              content={
                <>
                  <IconContainer type="default">
                    <ResourceIcon
                      type="HPSmart"
                      size={32}
                      color="colorGray10"
                    ></ResourceIcon>
                  </IconContainer>
                  <ItemContent type="default">
                    <ItemTitle
                      className={isMobile ? 'Caption' : 'label'}
                      id={formatAutoId(`${component}_Title_${'HPSmart'}`)}
                    >
                      {t('common.openTheHPSmartApp', `Open the HP Smart App`)}
                    </ItemTitle>
                    {description && (
                      <ItemDescription
                        id={formatAutoId(`${component}_Desc_${'HPSmart'}`)}
                        className="caption-small"
                      >
                        {t(
                          'common.accessAccountInformation',
                          'Access account information, see ink and paper supply levels, diagnose and fix printer issues with the HP Smart App'
                        )}
                      </ItemDescription>
                    )}
                  </ItemContent>
                </>
              }
            ></Box>
          </Col>
        </FlexRow>
      )}
    </>
  )
}

export default memo(ListView)
